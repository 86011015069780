body .header-main-actions__el_theme path {
  -webkit-transition: fill ease-in-out 0.3s;
  transition: fill ease-in-out 0.3s;
}

body .header-main-actions__el_theme .sun {
  fill: #2A59FF;
}

body .header-main-actions__el_theme .moon {
  fill: #8B98AA;
}

@media (max-width: 1200px) {
  body .header {
    border-bottom: 1px solid #B5BACB;
  }
}

body .header .header-main {
  border-top: 1px solid #B5BACB;
  border-bottom: 1px solid #B5BACB;
}

@media (max-width: 1200px) {
  body .header .header-main {
    border: 0;
  }
}

body .header .header-main::before {
  opacity: 1;
}

body .header .header-main::after {
  opacity: 0;
}

body .header .header-main .logo-header__img-dark {
  opacity: 0;
}

body .header .header-main .logo-header__img-light {
  opacity: 1;
}

body .header .header-main .logo-header__mobile {
  color: #4A596D;
}

body .header .header-main .logo-header__mobile::before {
  background-color: #B5BACB;
}

body .header .header-main .actions-main-header__download {
  border: 1px solid #B5BACB;
}

body .header .header-main .actions-main-header__download.disabled {
  border: 1px solid #d1d5e0;
}

body .header .header-main .actions-main-header__download:not(.disabled)::before {
  opacity: 0;
}

body .header .header-main .actions-main-header .header-search__input {
  color: #1E222A;
}

body .header .header-main .header-navigation .header-navigation__link {
  color: #1E222A;
}

body .header .header-main .header-navigation .header-navigation__el:hover .header-navigation__link {
  color: #2A59FF;
}

body .header .header-main .header-navigation .header-navigation__el:not(:last-child)::after {
  background-color: #8B98AA;
}

@media (max-width: 1200px) {
  body .header .header-main .header-navigation .header-navigation__el:not(:last-child) {
    border-bottom: 1px solid #B5BACB;
  }
}

body .header .header-main .header-navigation .header-navigation__el .header-submenu__wrapper {
  background-color: rgba(243, 247, 251, 0.9);
}

@media (max-width: 1200px) {
  body .header .header-main .header-navigation .header-navigation__el .header-submenu__wrapper {
    background-color: transparent;
  }
}

body .header .header-main .submenu-item__text {
  color: #515764;
}

body .header .header-main .submenu-item__icon {
  border: 1px solid #B5BACB;
}

body .header .header-main .submenu-item:hover .submenu-item__text {
  color: #2A59FF;
}

body .header .header-main .header-submenu__section:last-child {
  border-top: 1px solid #B5BACB;
}

@media (max-width: 1200px) {
  body .header .header-main .header-submenu__section:last-child {
    border-top: 0;
  }
}


body .header .burger-head-panel .change-theme {
  border: 1px solid #B5BACB;
}

body .header .burger-head-panel .change-theme::before {
  opacity: 0;
}

body .header .burger-head-panel .actions-main-header__download i {
  color: #2A59FF;
}

body .header .burger-head-panel .actions-main-header__download:hover i {
  color: #fff;
}
body .header .burger-head-panel .actions-main-header__download {
  color: #2A59FF;
}

body .header .burger-head-panel .actions-main-header__download:hover {
  color: #fff;
}

@media (max-width: 1200px) {
  body .header .header-navigation {
    background-color: rgba(243, 247, 251, 0.9);
  }
}

body .header .bottom-panel-navigation__button {
  border: 1px solid #B5BACB;
}

body .header .bottom-panel-navigation__button:not(.disabled) i {
  color: #2A59FF;
}

body .header .bottom-panel-navigation__button:not(.disabled)::before {
  opacity: 0;
}

body .header .bottom-panel-navigation__button:hover {
  border: 1px solid transparent;
}

body .header .bottom-panel-navigation__button:hover i {
  color: #fff;
}

body .header .bottom-panel-navigation .stroke-button {
  color: #4A596D;
  border: 1px solid #B5BACB;
}

body .header .bottom-panel-navigation .stroke-button::before {
  opacity: 0;
}

body .header .header-burger._active {
  background-color: #2A59FF;
  border: 1px solid #2A59FF;
}

body .header .header-burger._active span {
  background-color: #fff;
}

body .header .header-burger._active::before {
  opacity: 0;
}

body .header .header-main-actions__select-wrapper::before {
  opacity: 1;
}

body .header .header-main-actions__select-wrapper::after {
  opacity: 0;
}

@media (prefers-color-scheme: dark) {
  body .header-main-actions__el_theme .moon {
    fill: #2A59FF;
  }

  body .header-main-actions__el_theme .sun {
    fill: #8B98AA;
  }
}

@media (prefers-color-scheme: dark) and (max-width: 1200px) {
  body .header {
    border-bottom: 1px solid #323642;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .header-main-actions__el:not(:last-child) {
    border-right: 1px solid #323642;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .info-header-panel__el:not(:last-child) {
    border-right: 1px solid #323642;
  }
}

@media (prefers-color-scheme: dark) {

  body .header .header-main-actions__name,
  body .header .info-header-panel__title {
    color: #8B98AA;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .doc_link,
  body .header .info-header-panel__value {
    color: #fff;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .select__body {
    background-color: rgba(27, 32, 49, 0.95);
  }

  body .header .select__body .select__item {
    color: #8B98AA;
  }

  body .header .select__body .select__item:hover {
    background-color: #2d3036;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .header-main-actions__el:hover .header-main-actions__name {
    color: #7c8797;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .header-panel-actions__log .stroke-button {
    color: #2A59FF;
  }

  body .header .header-panel-actions__log .stroke-button:hover {
    color: #fff;
  }

  body .header .header-panel-actions__log .filled-button:hover {
    color: #2A59FF;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .header-main {
    border-top: 1px solid #1C2951;
    border-bottom: 1px solid #1C2951;
  }
}

@media (prefers-color-scheme: dark) and (max-width: 1200px) {
  body .header .header-main {
    border: 0;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .header-main::before {
    opacity: 0;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .header-main::after {
    opacity: 1;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .header-main .logo-header__img-dark {
    opacity: 1;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .header-main .logo-header__img-light {
    opacity: 0;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .header-main .logo-header__mobile {
    color: #707F94;
  }

  body .header .header-main .logo-header__mobile::before {
    background-color: #323642;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .header-main .actions-main-header__download {
    border: 1px solid rgba(69, 78, 83, 0.5);
  }
  body .header .header-main .actions-main-header__download.disabled {
    border: 1px solid rgba(69, 78, 83, 0.25);
  }
  body .header .header-main .actions-main-header__download:not(.disabled)::before {
    opacity: 1;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .header-main .actions-main-header .header-search__input {
    color: #fff;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .header-main .header-navigation .header-navigation__link {
    color: #fff;
  }

  body .header .header-main .header-navigation .header-navigation__el:hover .header-navigation__link {
    color: #2A59FF;
  }
}

@media (prefers-color-scheme: dark) and (max-width: 1200px) {
  body .header .header-main .header-navigation .header-navigation__el:hover .header-navigation__link {
    color: #fff;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .header-main .header-navigation .header-navigation__el:not(:last-child)::after {
    background-color: #323642;
  }
}

@media (prefers-color-scheme: dark) and (max-width: 1200px) {
  body .header .header-main .header-navigation .header-navigation__el:not(:last-child) {
    border-bottom: 1px solid #323642;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .header-main .header-navigation .header-navigation__el .header-submenu__wrapper {
    background-color: rgba(27, 32, 49, 0.95);
  }
}

@media (prefers-color-scheme: dark) and (max-width: 1200px) {
  body .header .header-main .header-navigation .header-navigation__el .header-submenu__wrapper {
    background-color: transparent;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .header-main .submenu-item__text {
    color: #fff;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .header-main .submenu-item__icon {
    border: 1px solid #323642;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .header-main .submenu-item:hover .submenu-item__text {
    color: #2A59FF;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .header-main .header-submenu__section:last-child {
    border-top: 1px solid #1E222A;
  }
}

@media (prefers-color-scheme: dark) and (max-width: 1200px) {
  body .header .header-main .header-submenu__section:last-child {
    border-top: 0;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .burger-head-panel .change-theme {
    border: 1px solid rgba(69, 78, 83, 0.5019607843);
  }

  body .header .burger-head-panel .change-theme::before {
    opacity: 1;
  }

  body .header .burger-head-panel .actions-main-header__download i {
    color: #2A59FF;
  }

  body .header .burger-head-panel .actions-main-header__download:hover i {
    color: #fff;
  }

  body .header .burger-head-panel .actions-main-header__download {
    color: #2A59FF;
  }

  body .header .burger-head-panel .actions-main-header__download:hover {
    color: #fff;
  }
}

@media (prefers-color-scheme: dark) and (max-width: 1200px) {
  body .header .header-navigation {
    background-color: rgba(19, 22, 31, 0.9803921569);
  }
}

@media (prefers-color-scheme: dark) {
  body .header .bottom-panel-navigation__button {
    border: 1px solid #323642;
  }

  body .header .bottom-panel-navigation__button i {
    color: #fff;
  }

  body .header .bottom-panel-navigation__button:not(.disabled)::before {
    opacity: 1;
  }

  body .header .bottom-panel-navigation__button:hover {
    border: 1px solid transparent;
  }

  body .header .bottom-panel-navigation__button:hover i {
    color: #fff;
  }

  body .header .bottom-panel-navigation .stroke-button {
    color: #fff;
    border: 1px solid #323642;
  }

  body .header .bottom-panel-navigation .stroke-button::before {
    opacity: 1;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .header-burger._active {
    background-color: transparent;
    border: 1px solid rgba(69, 78, 83, 0.5019607843);
  }

  body .header .header-burger._active span {
    background-color: #2A59FF;
  }

  body .header .header-burger._active::before {
    opacity: 1;
  }
}

@media (prefers-color-scheme: dark) {
  body .header .header-main-actions__select-wrapper::before {
    opacity: 0;
  }

  body .header .header-main-actions__select-wrapper::after {
    opacity: 1;
  }
}

html.dark body .header-main-actions__el_theme .moon {
  fill: #2A59FF;
}

html.dark body .header-main-actions__el_theme .sun {
  fill: #8B98AA;
}

@media (max-width: 1200px) {
  html.dark body .header {
    border-bottom: 1px solid #323642;
  }
}

html.dark body .header .header-main-actions__el:not(:last-child) {
  border-right: 1px solid #323642;
}

html.dark body .header .info-header-panel__el:not(:last-child) {
  border-right: 1px solid #323642;
}

html.dark body .header .header-main-actions__name,
html.dark body .header .info-header-panel__title {
  color: #8B98AA;
}

html.dark body .header .doc_link,
html.dark body .header .info-header-panel__value {
  color: #fff;
}

html.dark body .header .select__body {
  background-color: rgba(27, 32, 49, 0.95);
}

html.dark body .header .select__body .select__item {
  color: #8B98AA;
}

html.dark body .header .select__body .select__item:hover {
  background-color: #2d3036;
}

html.dark body .header .header-main-actions__el:hover .header-main-actions__name {
  color: #7c8797;
}

html.dark body .header .header-panel-actions__log .stroke-button {
  color: #2A59FF;
}

html.dark body .header .header-panel-actions__log .stroke-button:hover {
  color: #fff;
}

html.dark body .header .header-panel-actions__log .filled-button:hover {
  color: #2A59FF;
}

html.dark body .header .header-main {
  border-top: 1px solid #1C2951;
  border-bottom: 1px solid #1C2951;
}

@media (max-width: 1200px) {
  html.dark body .header .header-main {
    border: 0;
  }
}

html.dark body .header .header-main::before {
  opacity: 0;
}

html.dark body .header .header-main::after {
  opacity: 1;
}

html.dark body .header .header-main .logo-header__img-dark {
  opacity: 1;
}

html.dark body .header .header-main .logo-header__img-light {
  opacity: 0;
}

html.dark body .header .header-main .logo-header__mobile {
  color: #707F94;
}

html.dark body .header .header-main .logo-header__mobile::before {
  background-color: #323642;
}

html.dark body .header .header-main .actions-main-header__download {
  border: 1px solid rgba(69, 78, 83, 0.5);
}
html.dark body .header .header-main .actions-main-header__download.disabled {
  border: 1px solid rgba(69, 78, 83, 0.25);
}

html.dark body .header .header-main .actions-main-header__download:not(.disabled)::before {
  opacity: 1;
}

html.dark body .header .header-main .actions-main-header .header-search__input {
  color: #fff;
}

html.dark body .header .header-main .header-navigation .header-navigation__link {
  color: #fff;
}

html.dark body .header .header-main .header-navigation .header-navigation__el:hover .header-navigation__link {
  color: #2A59FF;
}

@media (max-width: 1200px) {
  html.dark body .header .header-main .header-navigation .header-navigation__el:hover .header-navigation__link {
    color: #fff;
  }
}

html.dark body .header .header-main .header-navigation .header-navigation__el:not(:last-child)::after {
  background-color: #323642;
}

@media (max-width: 1200px) {
  html.dark body .header .header-main .header-navigation .header-navigation__el:not(:last-child) {
    border-bottom: 1px solid #323642;
  }
}

html.dark body .header .header-main .header-navigation .header-navigation__el .header-submenu__wrapper {
  background-color: rgba(27, 32, 49, 0.95);
}

@media (max-width: 1200px) {
  html.dark body .header .header-main .header-navigation .header-navigation__el .header-submenu__wrapper {
    background-color: transparent;
  }
}

html.dark body .header .header-main .submenu-item__text {
  color: #fff;
}

html.dark body .header .header-main .submenu-item__icon {
  border: 1px solid #323642;
}

html.dark body .header .header-main .submenu-item:hover .submenu-item__text {
  color: #2A59FF;
}

html.dark body .header .header-main .header-submenu__section:last-child {
  border-top: 1px solid #1E222A;
}

@media (max-width: 1200px) {
  html.dark body .header .header-main .header-submenu__section:last-child {
    border-top: 0;
  }
}


html.dark body .header .burger-head-panel .change-theme {
  border: 1px solid rgba(69, 78, 83, 0.5019607843);
}

html.dark body .header .burger-head-panel .change-theme::before {
  opacity: 1;
}

html.dark body .header .burger-head-panel .actions-main-header__download i {
  color: #2A59FF;
}

html.dark body .header .burger-head-panel .actions-main-header__download:hover i {
  color: #fff;
}
html.dark body .header .burger-head-panel .actions-main-header__download {
  color: #2A59FF;
}

html.dark body .header .burger-head-panel .actions-main-header__download:hover {
  color: #fff;
}

@media (max-width: 1200px) {
  html.dark body .header .header-navigation {
    background-color: rgba(19, 22, 31, 0.9803921569);
  }
}

html.dark body .header .bottom-panel-navigation__button {
  border: 1px solid #323642;
}

html.dark body .header .bottom-panel-navigation__button i {
  color: #fff;
}

html.dark body .header .bottom-panel-navigation__button:not(.disabled)::before {
  opacity: 1;
}

html.dark body .header .bottom-panel-navigation__button:hover {
  border: 1px solid transparent;
}

html.dark body .header .bottom-panel-navigation__button:hover i {
  color: #fff;
}

html.dark body .header .bottom-panel-navigation .stroke-button {
  color: #fff;
  border: 1px solid #323642;
}

html.dark body .header .bottom-panel-navigation .stroke-button::before {
  opacity: 1;
}

html.dark body .header .header-burger._active {
  background-color: transparent;
  border: 1px solid rgba(69, 78, 83, 0.5019607843);
}

html.dark body .header .header-burger._active span {
  background-color: #2A59FF;
}

html.dark body .header .header-burger._active::before {
  opacity: 1;
}

html.dark body .header .header-main-actions__select-wrapper::before {
  opacity: 0;
}

html.dark body .header .header-main-actions__select-wrapper::after {
  opacity: 1;
}

@media (max-width: 1200px) {
  html.light body .header {
    border-bottom: 1px solid #B5BACB;
  }
}

html.light body .header .header-main-actions__el:not(:last-child) {
  border-right: 1px solid #B5BACB;
}

html.light body .header .info-header-panel__el:not(:last-child) {
  border-right: 1px solid #B5BACB;
}

html.light body .header .header-main-actions__name,
html.light body .header .info-header-panel__title {
  color: #1E222A;
}

html.light body .header .doc_link,
html.light body .header .info-header-panel__value {
  color: #2A59FF;
}

html.light body .header .select__body {
  background-color: rgba(243, 247, 251, 0.9);
}

html.light body .header .select__body .select__item {
  color: #1E222A;
}

html.light body .header .select__body .select__item:hover {
  background-color: #e9e9e9;
}

html.light body .header .header-main-actions__el:hover .header-main-actions__name {
  color: #515764;
}

html.light body .header .header-panel-actions__log .stroke-button {
  color: #2A59FF;
}

html.light body .header .header-panel-actions__log .stroke-button:hover {
  color: #fff;
}

html.light body .header .header-panel-actions__log .filled-button:hover {
  color: #2A59FF;
}

html.light body .header .actions-main-header__download {
  border: 1px solid #B5BACB;
}

html.light body .header .actions-main-header__download.disabled {
  border: 1px solid #d1d5e0;
}

html.light body .header .actions-main-header__download:not(.disabled)::before {
  opacity: 0;
}

html.light body .header .header-main {
  border-top: 1px solid #B5BACB;
  border-bottom: 1px solid #B5BACB;
}

@media (max-width: 1200px) {
  html.light body .header .header-main {
    border: 0;
  }
}

html.light body .header .header-main::before {
  opacity: 1;
}

html.light body .header .header-main::after {
  opacity: 0;
}

html.light body .header .header-main .logo-header__img-dark {
  opacity: 0;
}

html.light body .header .header-main .logo-header__img-light {
  opacity: 1;
}

html.light body .header .header-main .logo-header__mobile {
  color: #4A596D;
}

html.light body .header .header-main .logo-header__mobile::before {
  background-color: #B5BACB;
}

html.light body .header .header-main .actions-main-header .header-search__input {
  color: #1E222A;
}

html.light body .header .header-navigation .header-navigation__link {
  color: #1E222A;
}

html.light body .header .header-navigation .header-navigation__el:hover .header-navigation__link {
  color: #2A59FF;
}

@media (max-width: 1200px) {
  html.light body .header .header-navigation .header-navigation__el:hover .header-navigation__link {
    color: #1E222A;
  }
}

html.light body .header .header-navigation .header-navigation__el:not(:last-child)::after {
  background-color: #8B98AA;
}

@media (max-width: 1200px) {
  html.light body .header .header-navigation .header-navigation__el:not(:last-child) {
    border-bottom: 1px solid #B5BACB;
  }
}

html.light body .header .header-navigation .header-navigation__el .header-submenu__wrapper {
  background-color: rgba(243, 247, 251, 0.9);
}

@media (max-width: 1200px) {
  html.light body .header .header-navigation .header-navigation__el .header-submenu__wrapper {
    background-color: transparent;
  }
}

html.light body .header .submenu-item__text {
  color: #515764;
}

html.light body .header .submenu-item__icon {
  border: 1px solid #B5BACB;
}

html.light body .header .submenu-item:hover .submenu-item__text {
  color: #2A59FF;
}

html.light body .header .header-submenu__section:last-child {
  border-top: 1px solid #B5BACB;
}

@media (max-width: 1200px) {
  html.light body .header .header-submenu__section:last-child {
    border-top: 0;
  }
}

html.light body .header .burger-head-panel .change-theme::before {
  opacity: 0;
}

html.light body .header .burger-head-panel .actions-main-header__download i {
  color: #2A59FF;
}

html.light body .header .burger-head-panel .actions-main-header__download:hover i {
  color: #fff;
}

html.light body .header .burger-head-panel .actions-main-header__download {
  color: #2A59FF;
}

html.light body .header .burger-head-panel .actions-main-header__download:hover {
  color: #fff;
}

@media (max-width: 1200px) {
  html.light body .header .header-navigation {
    background-color: rgba(243, 247, 251, 0.9);
  }
}

html.light body .header .bottom-panel-navigation__button {
  border: 1px solid #B5BACB;
}

html.light body .header .bottom-panel-navigation__button:not(.disabled) i {
  color: #2A59FF;
}

html.light body .header .bottom-panel-navigation__button:not(.disabled)::before {
  opacity: 0;
}

html.light body .header .bottom-panel-navigation__button:hover {
  border: 1px solid transparent;
}

html.light body .header .bottom-panel-navigation__button:hover i {
  color: #fff;
}

html.light body .header .bottom-panel-navigation .stroke-button {
  color: #4A596D;
  border: 1px solid #B5BACB;
}

html.light body .header .bottom-panel-navigation .stroke-button::before {
  opacity: 0;
}

html.light body .header .header-burger._active {
  background-color: #2A59FF;
  border: 1px solid #2A59FF;
}

html.light body .header .header-burger._active span {
  background-color: #fff;
}

html.light body .header .header-burger._active::before {
  opacity: 0;
}

html.light body .header .header-main-actions__select-wrapper::before {
  opacity: 1;
}

html.light body .header .header-main-actions__select-wrapper::after {
  opacity: 0;
}

.header-burger {
  display: none;
  width: 40px;
  height: 40px;
  padding: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 6px;
  background-color: #2A59FF;
  -webkit-transition: background-color cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s;
  transition: background-color cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s;
  z-index: 125;
  margin-left: 10px;
  position: relative;
}

@media (max-width: 1200px) {
  .header-burger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (max-width: 480px) {
  .header-burger {
    padding: 9px;
    width: 35px;
    height: 35px;
    border: 1px solid transparent;
    -webkit-transition: border cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s;
    transition: border cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s;
    margin-left: 7px;
  }

  .header-burger span {
    height: 2.6px;
    -webkit-transition: background-color cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s;
    transition: background-color cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s;
  }
}

.header-burger::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  background: linear-gradient(191.45deg, rgba(42, 46, 58, 0.4) -73.23%, rgba(42, 46, 58, 0) 91.58%);
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
}

.header-burger span {
  height: 3px;
  background-color: #fff;
  border-radius: 6px;
  pointer-events: none;
}

.header-burger span:nth-child(1) {
  width: 9px;
  margin-right: auto;
  -webkit-transition: -webkit-transform cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s;
  transition: -webkit-transform cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s;
  transition: transform cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s;
  transition: transform cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s, -webkit-transform cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s;
}

@media (max-width: 480px) {
  .header-burger span:nth-child(1) {
    width: 7.8px;
  }
}

.header-burger span:nth-child(2) {
  width: 100%;
  -webkit-transition: -webkit-transform cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s;
  transition: -webkit-transform cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s;
  transition: transform cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s;
  transition: transform cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s, -webkit-transform cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s;
}

.header-burger span:nth-child(3) {
  width: 9px;
  margin-left: auto;
  -webkit-transition: -webkit-transform cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s;
  transition: -webkit-transform cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s;
  transition: transform cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s;
  transition: transform cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s, -webkit-transform cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s;
}

@media (max-width: 480px) {
  .header-burger span:nth-child(3) {
    width: 7.8px;
  }
}

.header-burger._active {
  background-color: transparent;
  position: fixed;
  right: 10px;
  top: 15px;
}

@media (max-width: 480px) {
  .header-burger._active {
    top: calc(7.5px + 10.5 * (100vw - 320px) / 160);
  }
}

.header-burger._active span:nth-child(1) {
  -webkit-transform: rotate(45deg) translate(5px, 2.5px);
  transform: rotate(45deg) translate(5px, 2.5px);
}

@media (max-width: 480px) {
  .header-burger._active span:nth-child(1) {
    -webkit-transform: rotate(45deg) translate(3.5px, 1.5px);
    transform: rotate(45deg) translate(3.5px, 1.5px);
  }
}

.header-burger._active span:nth-child(2) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.header-burger._active span:nth-child(3) {
  -webkit-transform: rotate(45deg) translate(-4px, -2px);
  transform: rotate(45deg) translate(-4px, -2px);
}

@media (max-width: 480px) {
  .header-burger._active span:nth-child(3) {
    -webkit-transform: rotate(45deg) translate(-3.5px, -2px);
    transform: rotate(45deg) translate(-3.5px, -2px);
  }
}

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  width: 100%;
  z-index: 120;
}

.header__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.header .select__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.header .select__body {
  position: absolute;
  right: 0;
  top: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 2px 0;
  background-color: rgba(243, 247, 251, 0.9);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  opacity: 0;
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  pointer-events: none;
  -webkit-transition: opacity ease-in-out 0.3s, background-color ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s, background-color ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s, transform ease-in-out 0.3s, background-color ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s, transform ease-in-out 0.3s, background-color ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
}

.header .select__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  padding: 6px 8px;
  -webkit-transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;
}

.header .select__item:hover {
  background-color: #eeeeee;
}

.header .select.is-active .select__body {
  opacity: 1;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  pointer-events: all;
}

.header .header-main-actions__el_select._active .down-icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.burger-head-panel {
  display: none;
}

@media (max-width: 1200px) {
  .burger-head-panel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 8px 10px;
    height: 70px;
    flex-shrink: 0;
  }
}

@media (max-width: 480px) {
  .burger-head-panel {
    height: calc(50px + 20 * (100vw - 320px) / 160);
  }
}

.burger-head-panel__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
}

@media (max-width: 480px) {
  .burger-head-panel__actions {
    gap: 10px;
  }
}

.burger-head-panel__actions .header-main-actions__el:not(:last-child) {
  padding-right: 10px;
}

.burger-head-panel__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 8px;
  margin-right: 48px;
}

@media (max-width: 480px) {
  .burger-head-panel__buttons {
    margin-right: 42px;
    gap: 6px;
  }
}

.burger-head-panel .actions-main-header__download {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: transparent;
}

@media (max-width: 480px) {
  .burger-head-panel .actions-main-header__download {
    width: 35px;
    height: 35px;
  }

  .burger-head-panel .actions-main-header__download img {
    width: 14px;
  }
}

.burger-head-panel .actions-main-header__download i {
  color: #fff;
}

.burger-head-panel .actions-main-header__download:hover i {
  color: #fff;
}

.burger-head-panel .change-theme {
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  position: relative;
}

@media (max-width: 480px) {
  .burger-head-panel .change-theme {
    width: 35px;
    height: 35px;
  }
}

.burger-head-panel .change-theme::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(191.45deg, rgba(42, 46, 58, 0.4) -73.23%, rgba(42, 46, 58, 0) 91.58%);
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
}

.burger-head-panel .change-theme .header-main-actions__icon {
  margin: 0;
}

.header-panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header-panel__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1rem;
  max-width: 50%;
  overflow: auto;
}
.header-panel__info::-webkit-scrollbar-track {
  border-radius: 25rem;
  background-color: #eee;
}

.header-panel__info::-webkit-scrollbar {
  border-radius: 25rem;
  width: 4px;
  height: 4px;
  background-color: #eee;
}

.header-panel__info::-webkit-scrollbar-thumb {
  border-radius: 20rem;
  background-color: #bdbdbd;
}
.dark .header-panel__info::-webkit-scrollbar-track {
  background-color: #414a68;
}
.dark .header-panel__info::-webkit-scrollbar {
  background-color: #414a68;
}
.dark .header-panel__info::-webkit-scrollbar-thumb {
  background-color: #2c334b;
}


.header__container::-webkit-scrollbar-track {
  display: none;
  border-radius: 25rem;
  background-color: #eee;
}

.header__container::-webkit-scrollbar {
  display: none;
  width: 6px;
  height: 6px;
  background-color: #eee;
}

.header__container::-webkit-scrollbar-thumb {
  display: none;
  border-radius: 20rem;
  background-color: #bdbdbd;
}

@media (max-width: 1200px) {
  .header-panel__info {
    height: 100%;
  }
  .header-panel {
    height: 3rem
  }
  .header-panel__info {
    max-width: 100%;
  }
}

.header-panel__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-panel .header__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .header-panel .header__container {
    overflow-x: auto;
    height: 100%;
  }
}

.info-header-panel__el {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 6px;
  -webkit-transition: border-color ease-in-out 0.3s;
  transition: border-color ease-in-out 0.3s;
}

.info-header-panel__el:not(:last-child) {
  padding-right: 1rem;
}

.info-header-panel__title {
  font-family: Rubik;
  font-weight: 300;
  font-size: .85rem;
  line-height: 1;
  color: #1E222A;
  white-space: nowrap;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
}

.doc_link,
.info-header-panel__value {
  font-family: "Roboto";
  font-size: .85rem;
  font-weight: 400;
  line-height: 1;
  color: #2A59FF;
  white-space: nowrap;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
}

.doc_link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: .85rem;
  font-weight: 500;
}
.doc_link i {
  margin-left: .15rem;
}

.doc_link:not(:last-child) {
  margin-right: .75rem;
}

.doc_link:hover,
.doc_link:active {
  color: #1039cc
}

.header-panel-actions {
  z-index: 120;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 26px;
}

@media (max-width: 1200px) {
  .header-panel-actions {
    display: none;
  }
}

.header-panel-actions__main-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-panel-actions__log {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 6px;
}

.header-panel-actions__log .stroke-button {
  height: 33px;
  border-radius: 6px;
  padding: 0 17px;
}

.header-panel-actions__log .filled-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 33px;
  border-radius: 6px;
  padding: 0 15px;
}

.header-panel-actions__log .filled-button i {
  margin-right: .25rem;
}

.header-panel-actions__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: Rubik;
  font-weight: 500;
}

.header-main-actions__el {
  z-index: 121;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  -webkit-transition: border-color ease-in-out 0.3s;
  transition: border-color ease-in-out 0.3s;
}

.header-main-actions__el:not(:last-child) {
  padding-right: 20px;
}

.header-main-actions__el .header-main-actions__icon {
  pointer-events: none;
}

.header-main-actions__el .header-main-actions__name {
  pointer-events: none;
}

.header-main-actions__el .dynamic-part {
  -webkit-transition: fill ease-in-out 0.3s;
  transition: fill ease-in-out 0.3s;
}

.header-main-actions__el:hover .dynamic-part {
  fill: #1039cc;
}

.header-main-actions__el_select {
  position: relative;
  z-index: 120;
}

.header-main-actions__el_select .select {
  pointer-events: all;
}

.header-main-actions__el_select .down-icon {
  display: inline-block;
  font-size: .55rem;
  margin-left: 0.25rem;
  color: #2A59FF;
  pointer-events: none;
  -webkit-transition: -webkit-transform ease-in-out 0.3s;
  transition: -webkit-transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
}

.header-main-actions__el_theme .header-main-actions__icon {
  width: 17px;
  height: 17px;
}

.header-main-actions__icon {
  width: 16px;
  height: 16px;
  margin-right: 7px;
}

.header-main-actions__icon svg {
  width: 100%;
  color: #2a59ff;
  transition: color .3s ease-out;
  will-change: color;
}

.header-main-actions__icon i {
  font-size: 16px;
  color: #2a59ff;
  transition: color .3s ease-out;
  will-change: color;
}

.header-main-actions__icon svg,
.header-main-actions__icon i {
  color: #2a59ff
}

.header-main-actions__name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
}

.header-main-actions__name_currency {
  text-transform: uppercase;
}

.header-main-actions__el_theme:hover svg {
  -webkit-animation: windy 0.4s ease;
  animation: windy 0.4s ease;
}

@-webkit-keyframes windy {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  20% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }

  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes windy {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  20% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }

  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.header-main-actions__select-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 6px;
  border: 1px solid transparent;
  height: 33px;
  -webkit-transition: border ease-in-out 0.3s;
  transition: border ease-in-out 0.3s;
  padding: 0 6px;
  position: relative;
}

.header-main-actions__select-wrapper:hover {
  border: 1px solid #2A59FF;
}

.header-main-actions__select-wrapper::before {
  content: "";
  position: absolute;
  right: -10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
  height: 18px;
  background-color: #B5BACB;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
  opacity: 0;
}

.header-main-actions__select-wrapper::after {
  content: "";
  position: absolute;
  right: -10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
  height: 18px;
  background-color: #323642;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
  opacity: 0;
}

.header-main {
  position: relative;
  -webkit-transition: border ease-in-out 0.3s;
  transition: border ease-in-out 0.3s;
}

.header-main::before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(53.56deg, rgba(235, 242, 255, 0.5) -9.61%, rgba(241, 241, 241, 0.5) 90.49%);
  -webkit-box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
  box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
  opacity: 0;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
}

.header-main::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(53.56deg, rgba(30, 34, 42, 0.05) -9.61%, rgba(41, 50, 255, 0.05) 90.49%);
  opacity: 0;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
}

.header-main__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-main .header__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 70px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .header-main .header__container {
    -webkit-box-pack: stretch;
    -ms-flex-pack: stretch;
    justify-content: stretch;
  }
}

@media (max-width: 480px) {
  .header-main .header__container {
    height: calc(50px + 20 * (100vw - 320px) / 160);
  }
}

.header-main__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 7px;
}

@media (max-width: 1200px) {
  .logo-header {
    margin-right: auto;
  }
}

.logo-header__desktop {
  width: 225px;
  height: 100%;
  position: relative;
}

.logo-header__desktop img {
  position: absolute;
  width: 100%;
  height: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 480px) {
  .logo-header__desktop {
    width: calc(160px + 65 * (100vw - 320px) / 160);
  }
}

.logo-header__img-light {
  opacity: 0;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
}

.logo-header__img-dark {
  opacity: 0;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
}

.logo-header__mobile {
  margin-left: 20px;
  padding-left: 20px;
  position: relative;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
  font-family: Rubik;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

.logo-header__mobile::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 1px;
  height: 18px;
  background-color: aquamarine;
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
}

@media (max-width: 480px) {
  .logo-header__mobile {
    display: none;
  }
}

.actions-main-header__download {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 45px;
  height: 45px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  border-radius: 10px;
  -webkit-transition: border ease-in-out 0.3s, background-color ease-in-out 0.3s;
  transition: border ease-in-out 0.3s, background-color ease-in-out 0.3s;
  position: relative;
  will-change: border, background-color, color;
}

.actions-main-header__download i {
  color: #1e222a;
  font-size: 1.6rem;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
}
.dark .actions-main-header__download i {
  color: #fff;
}
.actions-main-header__download.disabled i {
  color: #9e9e9e
}
.dark .actions-main-header__download.disabled i {
  color: #707F94;
}

@media (max-width: 1200px) {
  .actions-main-header__download {
    display: none;
  }
  .actions-main-header__download i {
    color: inherit;
    font-size: 1.25rem;
  }
}

.actions-main-header__download:not(.disabled):hover {
  background-color: #2A59FF;
}

.actions-main-header .actions-main-header__download_app-store:not(.disabled):hover {
  background-color: #2A59FF;
}

.actions-main-header .actions-main-header__download_app-store:not(.disabled):hover i {
  color: #fff;
}

.header-main__navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

@media (max-width: 1200px) {
  .header-navigation {
    display: flex;
    position: fixed;
    -webkit-filter: blur(10px);
    filter: blur(10px);
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition: background-color ease-in-out 0.3s, opacity ease-in-out 0.3s, -webkit-filter ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
    transition: background-color ease-in-out 0.3s, opacity ease-in-out 0.3s, -webkit-filter ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
    transition: background-color ease-in-out 0.3s, opacity ease-in-out 0.3s, filter ease-in-out 0.3s, transform ease-in-out 0.3s;
    transition: background-color ease-in-out 0.3s, opacity ease-in-out 0.3s, filter ease-in-out 0.3s, transform ease-in-out 0.3s, -webkit-filter ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
    z-index: 124;
    opacity: 0;
    pointer-events: none;
  }
  .header-navigation._active {
    width: 100%;
    height: 100%
  }
}

.header-navigation__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  z-index: 20;
}

@media (max-width: 1200px) {
  .header-navigation__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    padding: 0 10px;
    margin-top: 8px;
    gap: 0;
    overflow: auto;
  }
}

.header-navigation__el {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 1200px) {
  .header-navigation__el {
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-transition: border ease-in-out 0.3s;
    transition: border ease-in-out 0.3s;
  }
}

.header-navigation__el:not(:last-child)::after {
  content: "";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 13px;
  height: 1px;
  background-color: #8B98AA;
  margin-left: 20px;
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
}

@media (max-width: 1200px) {
  .header-navigation__el:not(:last-child)::after {
    display: none;
  }
}

.header-navigation__el_submenu:hover .header-submenu__body {
  opacity: 1;
  pointer-events: all;
}

.header-navigation__el:hover .header-navigation__link {
  color: #2A59FF;
}

.header-navigation__el:hover .header-navigation__link::after {
  opacity: 1;
}

.header-navigation__link {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  position: relative;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
  font-family: "Roboto";
  font-weight: 400;
}

@media (max-width: 1200px) {
  .header-navigation__link {
    height: 52px;
    width: 100%;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    font-weight: 700;
  }

  .header-navigation__link::after {
    display: none;
  }
}

.header-navigation__link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #2A59FF;
  opacity: 0;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
}

.header-navigation._active {
  opacity: 1;
  pointer-events: all;
  -webkit-filter: blur(0px);
  filter: blur(0px);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.header-submenu {
  position: relative;
}

.header-submenu__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 20px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  padding: 26px 24px;
  -webkit-transition: gap cubic-bezier(0.79, 0.33, 0.14, 0.53) 0.3s;
  transition: gap cubic-bezier(0.79, 0.33, 0.14, 0.53) 0.3s;
}

@media (max-width: 1200px) {
  .header-submenu__wrapper {
    padding: 0;
    gap: 0;
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }
}

.header-submenu__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  left: 0px;
  top: 100%;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
}

@media (max-width: 1200px) {
  .header-submenu__body {
    position: static;
    overflow: hidden;
    opacity: 1;
    pointer-events: all;
    -webkit-transition: padding ease-in-out 0.3s;
    transition: padding ease-in-out 0.3s;
  }
}

.header-submenu__section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 16px;
}

.header-submenu__section:not(:first-child) {
  padding-top: 16px;
}

@media (max-width: 1200px) {
  .header-submenu__section:not(:first-child) {
    padding: 0;
  }
}

@media (max-width: 1200px) {
  .header-submenu__section {
    border: 0;
    gap: 0;
    -webkit-transition: gap ease-in-out 0.3s;
    transition: gap ease-in-out 0.3s;
  }

  .header-submenu__section:last-child {
    border-top: 0;
  }
}

.header-submenu__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  gap: 10px;
  -webkit-transition: gap ease-in-out 0.3s;
  transition: gap ease-in-out 0.3s;
}

@media (max-width: 1200px) {
  .header-submenu__list {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    gap: 0;
  }
}

.header-submenu__section-headline {
  font-family: Rubik;
  font-weight: 300;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 15px;
  color: #8B98AA;
}

@media (max-width: 1200px) {
  .header-submenu__section-headline {
    display: none;
  }
}

.header-submenu__el {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}

.header-submenu__el.submenu-disabled {
  cursor: default;
  pointer-events: none;
  opacity: .5;
}

.header-submenu__el:hover, .submenu-item__icon
.header-submenu__el:active .submenu-item__icon {
  color: #2A59FF;
}

.submenu-item__icon {
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20px;
  flex: 0 0 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 4px;
}

@media (max-width: 1200px) {
  .submenu-item__icon {
    display: none;
  }
}

.submenu-item__icon img,
.submenu-item__icon i {
  color: #2A59FF;
  width: 10px;
  font-size: 10px;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
  will-change: opacity;
}

.submenu-item__text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: Rubik;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  white-space: nowrap;
  -webkit-transition: color ease-in-out 0.3s, line-height ease-in-out 0.3s, opacity ease-in-out 0.3s;
  transition: color ease-in-out 0.3s, line-height ease-in-out 0.3s, opacity ease-in-out 0.3s;
}
.submenu-item__text i {
  font-size: 85%;
  margin-left: .15rem;
}

@media (max-width: 1200px) {
  .submenu-item__text {
    padding-right: 8px;
    position: relative;
    line-height: 0%;
    opacity: 0;
  }

  .submenu-item__text:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #2A59FF;
  }
}

@media (max-width: 1200px) {
  .header-submenu._opened .submenu-item__text {
    line-height: 240%;
    opacity: 1;
  }

  .header-submenu._opened .header-submenu__list {
    gap: 10px;
  }

  .header-submenu._opened .header-submenu__wrapper {
    gap: 20px;
  }

  .header-submenu._opened .header-submenu__body {
    padding-bottom: 16px;
  }
}

.header-navigation__bottom-panel {
  display: none;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 10px;
  padding-bottom: 20px;
  gap: 20px;
}

@media (max-width: 1200px) {
  .header-navigation__bottom-panel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.header-navigation__bottom-panel .header-panel-actions__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 6px;
}

.header-navigation__bottom-panel .header-panel-actions__button i {
  margin-right: .25rem;
}

.bottom-panel-navigation__log {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
  max-width: 300px;
  gap: 1rem;
  width: 100%;
}

.bottom-panel-navigation__buttons {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}

.bottom-panel-navigation__title {
  margin-right: 5px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 15px;
  line-height: 105.19%;
  text-align: right;
}

.bottom-panel-navigation__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  -webkit-transition: border ease-in-out 0.3s, background-color ease-in-out 0.3s;
  transition: border ease-in-out 0.3s, background-color ease-in-out 0.3s;
  position: relative;
}

.bottom-panel-navigation__button i {
  font-size: 1.5rem;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
  will-change: color;
}

.bottom-panel-navigation__button:not(.disabled)::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(191.45deg, rgba(42, 46, 58, 0.4) -73.23%, rgba(42, 46, 58, 0) 91.58%);
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
}

.bottom-panel-navigation__button:hover {
  background-color: #2A59FF;
}

.bottom-panel-navigation__button:hover i {
  color: #fff;
}
.bottom-panel-navigation__button.disabled {
  background: #8ea8fd;
  cursor: default;
}
.bottom-panel-navigation__button.disabled i {
  color: #fff
}

.bottom-panel-navigation .stroke-button {
  -webkit-transition: border ease-in-out 0.3s, color ease-in-out 0.3s;
  transition: border ease-in-out 0.3s, color ease-in-out 0.3s;
  position: relative;
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
}

.bottom-panel-navigation .stroke-button:hover {
  background-color: rgba(16, 57, 204, 0.1568627451);
  color: #fff;
}

.bottom-panel-navigation .stroke-button::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(191.45deg, rgba(42, 46, 58, 0.4) -73.23%, rgba(42, 46, 58, 0) 91.58%);
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
}

.bottom-panel-navigation .filled-button:hover {
  background-color: #1039cc;
  color: #fff;
}
